import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { OrganizationService } from './organization.service';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { environment } from '../../environments/environment';
import { catchError, filter, switchMap, take } from "rxjs/operators";
import { RefreshAccessTokenResponse } from "@app/models";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	private isRefreshing = false;
	private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

	constructor(private userService: UserService, private organizationService: OrganizationService, private router: Router) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!req.url.includes(environment.apiUrl) &&
			!req.url.includes(environment.accountUrl) &&
			!req.url.includes(environment.integrationUrl) &&
			!req.url.includes(environment.genAiUrl)) return next.handle(req);

		if(this.userService.jwtToken) {
			req = req.clone({
				headers: req.headers.append('Authorization',`Bearer ${this.userService.jwtToken}`)
			});
		}

		if(this.organizationService.currentOrganization.id) {
			if(!req.url.includes(environment.genAiUrl)) {
				req = req.clone({
					headers: req.headers.append('organization', this.organizationService.currentOrganization.alias)
				});
			} else {
				req = req.clone({
					headers: req.headers.append('organizationAlias', this.organizationService.currentOrganization.alias)
				});
			}
		}

		return next.handle(req).pipe(
			catchError((error: HttpErrorResponse) => {
				if (error.status === 401 && !this.isRefreshing) {
					return this.handle401Error(req, next);
				}
				return throwError(error);
			})
		);
	}

	private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
		if (!this.isRefreshing) {
			this.isRefreshing = true;
			this.refreshTokenSubject.next(null);

			return this.userService.refreshAccessToken().pipe(
				switchMap((response: RefreshAccessTokenResponse) => {
					this.isRefreshing = false;

					this.userService.jwtToken = response.accessToken;
					this.refreshTokenSubject.next(response.accessToken);
					localStorage.setItem('jwtToken', response.accessToken);

					return next.handle(this.addToken(request, response.accessToken));
				}),
				catchError((err) => {
					this.isRefreshing = false;
					this.userService.logout();
					return throwError(err);
				})
			);
		} else {
			return this.refreshTokenSubject.pipe(
				filter(token => token != null),
				take(1),
				switchMap(jwtToken => next.handle(this.addToken(request, jwtToken!)))
			);
		}
	}

	private addToken(request: HttpRequest<any>, token: string) {
		let headers = request.headers.set('Authorization', `Bearer ${token}`);

		if (this.organizationService.currentOrganization.id) {
			if (!request.url.includes(environment.genAiUrl)) {
				headers = headers.set('organization', this.organizationService.currentOrganization.alias);
			} else {
				headers = headers.set('organizationAlias', this.organizationService.currentOrganization.alias);
			}
		}

		return request.clone({ headers });
	}

}
