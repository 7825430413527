import { Deserializable, OrganizationAiModel } from ".";

export class GenAiTip implements Deserializable {
    public id: number;
	public icon: string;
	public title: string;
	public prompt: string;
	public organizationAiModelId: number;
	public createdAt: Date;
	public updatedAt: Date;

	public organizationAiModel: OrganizationAiModel;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
