export const environment = {
	COMPANY_NAME: "evollo",
	customer: 'tokiomarine',
	production: true,
	APP_NAME: "QUALITY",
	apiUrl: "https://tokiomarine-quality.api.evollo.cloud",
	accountUrl: "https://tokiomarine-account.api.evollo.cloud",
	integrationUrl: "https://tokiomarine-integration.api.evollo.cloud",
	genAiUrl: 'https://genai-integrator-api.evollo.cloud:8485',
	features: {
		enabledAverageGrade: true,
		enabledChat: false,
		enableQualityDashboard: false,
		enableInteractionSentiment: false,
		enableSupport: false,
		enableFaq: false,
		homepage: '/interactions',
		cycleAsContactDate: false, // false = data de monitoria || true = data de contato
		enableInteractionMonitorCycle: false,
		aiEnabled: true,
		aiInsightsEnabled: true,
		aiInteractionEnabled: true,
		trainingUrl: null,
		genAiOrganizationFilter: false,
		accessLogEnabled: true,
		initialInteractionDateFilter: true,
	},
	apps: {
		id: "https://tokiomarine.id.evollo.com.br",
		admin: "https://tokiomarine.admin.evollo.com.br",
	},
	firebaseConfig: {
		apiKey: "AIzaSyDuZYbwOh_vxbnmv8NKXiljM4EfNvzWerw",
		authDomain: "quality-ui.firebaseapp.com",
		projectId: "quality-ui",
		storageBucket: "quality-ui.firebasestorage.app",
		messagingSenderId: "1015646958751",
		appId: "1:1015646958751:web:cad29cdde2882247101eaa",
		measurementId: "G-B5135PSD8K"
	}
};
