<div class="gen-ai-chat" [ngClass]="{'filter-show' : showFilter}" *ngIf="filters">

	<div class="header-model" [ngClass]="{'interaction' : !!interaction }" *ngIf="selectedOrganizationAiModel">
		<div ngbDropdown container="body">
			<button class="btn btn-ai-model" ngbDropdownToggle>
				{{ selectedOrganizationAiModel.name }}
			</button>
			<div ngbDropdownMenu style="width: 250px; padding-bottom: 5px;">
				<div class="text-muted p-3">
					Agentes de IA
				</div>

				<button ngbDropdownItem *ngFor="let model of organizationAiModels" style="padding: 15px 20px;" (click)="changeOrganizationAiModel(model)">
					<div class="d-flex justify-content-between align-items-center">
						<span>{{ model.name }}</span>
						<i class="fa fa-check-circle text-success" *ngIf="selectedOrganizationAiModel == model"></i>
					</div>
				</button>
			</div>
		</div>
	</div>

	<div class="page-header w-100" *ngIf="showTitle" style="margin-bottom: 15px;">
		<div class="row align-items-center">
			<div class="col-sm">
				<h2 class="page-header-title">IA Generativa</h2>
				<div class="page-header-subtitle">
					Assistente virtual para insights, análises e recomendações.
				</div>
			</div>
		</div>
	</div>

	<div class="d-flex justify-content-center align-items-center flex-grow-1 flex-column" *ngIf="messages.length == 0">
		<img class="mb-5" src="/assets/images/evollo_marca_1_2_marca_fundos_claros.png" alt="Gen.ai" width="250" />

		<div class="my-5 text-center">
			<h1>Qual Insight você precisa agora?</h1>
		</div>

		<p style="font-size: 16px;" *ngIf="showGenAiTips">Comece com as nossas perguntas mais populares:</p>
		<div class="ai-tips" *ngIf="showGenAiTips">
			<div class="ai-tip" *ngFor="let tip of genAiTips; index as i" (click)="sendTipMessage(tip)" style="cursor: pointer">
				<i class="far" [ngClass]="[tip.icon, genAiTipsColors[i]]"></i>
				<p>{{ tip.title }}</p>
			</div>
		</div>
	</div>

	<div class="header text-center" *ngIf="messages.length > 0">
		<img class="mb-3" src="/assets/images/evollo_marca_1_2_marca_fundos_claros.png" alt="Gen.ai" width="200" />
	</div>

	<div class="chat-box" *ngIf="messages.length > 0">
		<div class="system-message" *ngIf="loading">
			<div class="logo">
				<img src="/assets/images/logo-evollo.png" alt="Gen.ai" />
			</div>
			<div class="message">
				<svg height="15" width="40" class="loader">
					<circle class="dot" cx="10" cy="10" r="3" style="fill:grey;" />
					<circle class="dot" cx="20" cy="10" r="3" style="fill:grey;" />
					<circle class="dot" cx="30" cy="10" r="3" style="fill:grey;" />
				</svg>
			</div>
		</div>

		<ng-container *ngFor="let message of messages; index as i">
			<div class="user-message" *ngIf="message.sender == 'user'">
				<div class="message">
					<markdown
						[data]="message.text">
					</markdown>
				</div>
				<div class="logo">
					<avatar-initials [small]="true" [initialsColor]="'#fff'" [name]="userService.currentUser.name"></avatar-initials>
				</div>
			</div>

			<div class="system-message" *ngIf="message.sender == 'system'">
				<div class="logo">
					<img src="/assets/images/logo-evollo.png" alt="Gen.ai" />
				</div>
				<markdown [data]="message.text">
				</markdown>
			</div>
		</ng-container>
	</div>

	<div class="w-100" style="height: 15px;"></div>

	<div class="chat-footer">

		<div class="d-flex w-100 align-items-end">
			<button class="btn btn-link" *ngIf="false">
				<i class="fa fa-eye-slash" (click)="showFiltersBadge = false" *ngIf="showFiltersBadge"></i>
				<i class="fa fa-eye" (click)="showFiltersBadge = true" *ngIf="!showFiltersBadge"></i>
			</button>

			<div ngbDropdown container="body">
				<button class="btn btn-link caret-off" ngbDropdownToggle (click)="$event.stopPropagation();">
					<i class="fas fa-ellipsis-v"></i>
				</button>
				<div ngbDropdownMenu>
					<button class="text-danger" evolloConfirmDangerAction [title]="'Limpar conversa'" [text]="'Deseja realmente limpar a conversa?'" (confirm)="resetChat()" ngbDropdownItem>Limpar conversa</button>
				</div>
			</div>

			<form class="w-100" [formGroup]="chatForm">
				<textarea class="form-control" formControlName="message" rows="1" placeholder="Como posso ajudar?" #textArea
				          (input)="adjustTextareaHeight($event)" (keydown)="handleKeydown($event)"></textarea>
			</form>

			<button class="btn btn-link" tooltip="Filtros" (click)="showFilter = !showFilter">
				<i class="fa fa-sliders-h"></i>
			</button>

			<button class="btn btn-link" (click)="sendMessage()" [disabled]="filters.invalid || chatForm.invalid || loading">
				<i class="fa fa-arrow-circle-up"></i>
			</button>
		</div>
	</div>

</div>

<app-ai-chat-filters [showFilter]="showFilter" [filters]="filters" [interaction]="interaction" (closeFilter)="showFilter = false" *ngIf="filters"></app-ai-chat-filters>
