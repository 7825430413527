import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ChecklistItemFilter } from '@app/models';
import { ChecklistService, ChecklistItemServiceV2 } from '@app/services';
import { HelpDeskService } from '@app/services';
import { SegmentService } from '@app/services';
import { FirstStepsService } from '@app/services';
import { Checklist } from '@app/models';
import { CreateChecklistService } from '@app/services';
import { ChecklistItemService } from '@app/services';
import { ToastService } from '@app/services';
import { ChecklistItem } from '@app/models';

@Component({
	selector: 'app-checklist-automatic-step-three',
	templateUrl: './checklist-automatic-step-three.component.html',
	styleUrls: ['./checklist-automatic-step-three.component.scss']
})
export class ChecklistAutomaticStepThreeComponent implements OnInit, OnDestroy {
	checklist: Checklist;
	categoriesSelected: {
		id: number,
		group: string,
		categories: any[]
	}[] = [];

	categoriesPesos: FormArray;

	subs = new Subscription();

	isLoading: boolean = false;

	get isAllCategoriesSaved() {
		return this.categories.reduce((acc, category) => (acc = acc ? category.isSaved : false), true);
	}

	get categories() {
		return this.categoriesSelected.reduce((acc, group) => (acc = [...acc, ...group.categories]), []);
	}

	get activeCategory() {
		return this.categories.find(category => category.isActive);
	}

	get pesoTotal() {
		if (!this.categoriesPesos?.value) {
			return 0;
		}

		const flattenedWeights = this.categoriesPesos.value.flatMap(group => group);

		const allNGC = flattenedWeights.every(categoryPeso => parseFloat(categoryPeso.peso) === 100);

		if (allNGC) {
			return 100;
		} else {
			const totalWeight = flattenedWeights.reduce((acc, categoryPeso) => {
				const peso = parseFloat(categoryPeso.peso);
				return (isNaN(peso) || peso === 100) ? acc : acc + peso;
			}, 0);

			return this.roundNumber(totalWeight, 2);
		}
	}



	constructor(private createChecklistService: CreateChecklistService,
	            public checklistItemService: ChecklistItemService,
	            public checklistService: ChecklistService,
				private segmentService: SegmentService,
				private firstStepsService: FirstStepsService,
	            private formBuilder: FormBuilder,
	            private activeModal: NgbActiveModal,
	            private helpDeskService: HelpDeskService,
	            private toastService: ToastService,
				public ChecklistItemServiceV2: ChecklistItemServiceV2) {
	}

	ngOnInit(): void {
		this.checklist = this.createChecklistService.currentChecklist;
		this.categoriesSelected = this.createChecklistService.automaticCategories;

		if (this.createChecklistService.currentChecklist.id) {
			this.createEditForm();
		} else {
			this.createForm();
		}
	}

	public showHelper(): void {
		this.helpDeskService.helperContent = `
			<h5>O que são os pesos?</h5>

			<p>Os pesos são os valores de penalizações que serão considerados em caso de não realização de cada item pelo operador.</p>

			<ul><li>Toda avaliação começa com um total de 100 pontos.</li>
			<li>O operador que não cumprir um item durante a interação, terá o valor do item descontado dos pontos.</li>
			<li>A somatória dos pesos para todos os itens deve ser 100 (exceto NCGs).</li></ul>

			<p>Caso entenda que algum item não cumprido deva zerar a interação, ou seja, uma não conformidade grave (NCG), selecione a opção NCG.</p>
		`;

		this.helpDeskService.showHelpSidebar = true;
	}

	private createForm(): void {
		this.categoriesPesos = this.formBuilder.array(this.categoriesSelected.map(group => {
			return this.formBuilder.array(group.categories.map(category => {
				return this.formBuilder.group({
					peso: [category.peso ? category.peso : 0, [Validators.min(0), Validators.max(100), Validators.required]],
					defaultAnswer: [ category.itemAnswer ? category.itemAnswer : null ]
				});
			}));
		}));

		this.categoriesPesos.setValidators([validatePesoTotal]);
		this.categoriesPesos.updateValueAndValidity();
	}

	private createEditForm(): void {
		this.checklistItemService.filterWithChecklist({} as ChecklistItemFilter, this.createChecklistService.currentChecklist.id).subscribe(
			data => {
				this.categoriesSelected.forEach(selectedGroup => {
					selectedGroup.categories.forEach(selectedCategory => {

						data.content.forEach(savedCategory => {

							if (savedCategory.speechCategoryId === selectedCategory._id) {
								selectedCategory.id = savedCategory.id;
								selectedCategory.peso = savedCategory.peso;
								selectedCategory.defaultAnswer = savedCategory.itemAnswer
								selectedCategory.ruleFragmentCategoryIA = savedCategory.ruleFragmentCategoryIA;
							}

						});

					});
				});

				this.createForm();
			});
	}

	getCategoryControl(i, j, field) {
		return ((this.categoriesPesos?.controls[i] as FormArray)?.controls[j] as FormGroup)?.controls[field] as FormControl;
	}

	resetNcgValue(category, weight, i, j) : void {
		let control = this.getCategoryControl(i, j, 'peso');

		if (weight == 100) {
			weight = 0;
		} else {
			weight = 100;
		}

		category.peso = weight;
		control.setValue(weight);
	}

	saveItem(category) {

		const item = new ChecklistItem().deserialize({
			id: category.id,
			descricao: category.description,
			name: category.name,
			peso: category.peso ? category.peso : 0,
			checklist: {
				id: this.createChecklistService.currentChecklist.id,
				type: this.createChecklistService.currentChecklist.checkListType
			},
			speechCategoryId: category._id,
			defaultAnswer: category.itemAnswer,
			itemAnswer: category.itemAnswer,
			group: {name: category.group},
			ruleFragmentCategoryIA: category.ruleFragmentCategoryIA
		});

		category.isSaved = true;
		category.peso = category.peso;
		category.id = item.id;
		category.isActive = false;
		this.createChecklistService.currentChecklist.itensChecklist.push(item);
	}

	onCategoryFocus(category) {
		category.isActive = true;
		category.isSaved = false;
	}

	onCategoryBlur(category, currentPeso) {
		category.isActive = false;
		category.isSaved = !!category.peso && currentPeso === category.peso;
		category.peso = currentPeso;
	}

	prevStep() {
		const modal = this.createChecklistService.showAlertOnPrev();
		this.subs.add(modal.closed.pipe(filter((isConfirmed: boolean) => !!isConfirmed)).subscribe(() => {
			this.createChecklistService.prevStep();
		}));
	}

	public nextStep() {
		this.isLoading = true;

		this.categoriesSelected.forEach(categorySelected => {
			categorySelected.categories.forEach(category => {
				this.saveItem(category);
			});
		});

		let finalItemsChecklist = [];

		this.createChecklistService.savedCategories.forEach( savedCategory => {
			finalItemsChecklist = [ ...finalItemsChecklist, this.castToChecklistItem(savedCategory) ];
		});

		this.createChecklistService.currentChecklist.itensChecklist.forEach( item => {
			if(!item.id) {
				finalItemsChecklist = [ ...finalItemsChecklist, this.castToChecklistItem(item) ];
			}
		});

		if (this.createChecklistService.currentChecklist.id) {

			let prevItems = [];
			this.createChecklistService.currentChecklist.itensChecklist.forEach(item => {
				if(!!item.active) {
					prevItems.push(item);
				}
			});

			let newItems = [];
			this.createChecklistService.currentChecklist.itensChecklist.forEach(item => {
				if(item.active !== true && item.active !== false) {
					newItems.push(item);
				}
			});

			this.createChecklistService.currentChecklist.itensChecklist.forEach(item => {
				item.checklist = {
					id: this.createChecklistService.currentChecklist.id,
					type: this.createChecklistService.currentChecklist.checkListType
				}
				item.checklist_id = this.createChecklistService.currentChecklist.id;
			});

			finalItemsChecklist.forEach(item => {
				let savedItem = newItems.find(item2 => item2.id == item.id);

				console.log(item);
				console.log('savedItem', savedItem);

				if(savedItem) {
					item.active = true;
					item.peso = savedItem.peso;
					item.name = savedItem.name;
					item.descricao = savedItem.descricao;
					item.defaultAnswer = savedItem.defaultAnswer;
					item.ruleFragmentCategoryIA = savedItem.ruleFragmentCategoryIA;
				} else {
					item.active = false;
				}
			});

			this.createChecklistService.currentChecklist.itensChecklist = [];

			this.checklistService.put(this.createChecklistService.currentChecklist, this.createChecklistService.currentChecklist.id).subscribe(
				data => {
					this.ChecklistItemServiceV2.updateItemsChecklist(finalItemsChecklist).subscribe(
						data => {
							this.toastService.showSuccess('Checklist alterado com sucesso!');
							this.activeModal.close();
						}, error => {
							this.toastService.showDanger('Erro ao salvar os itens do checklist.');
							console.error(error);
						}).add(() => this.isLoading = false);
				}, error => {
					this.isLoading = false
				});
		} else {
			this.createChecklistService.currentChecklist.itensChecklist = finalItemsChecklist;
			this.createChecklistService.currentChecklist.segmentId = this.createChecklistService.currentChecklist.segment.id;

			this.checklistService.post(this.createChecklistService.currentChecklist).subscribe(
				data => {
					this.toastService.showSuccess('Checklist criado com sucesso!');

					if (this.createChecklistService.isFirstSteps) {
						this.validateFirstSteps();
					} else {
						this.activeModal.close();
					}
				}, error => {
					if(error.error.error === 'CHECKLIST_ALREADY_EXIST') {
						if(this.createChecklistService.currentChecklist.metadata.length == 0) {
							this.toastService.showDanger('Já existe um checklist sem metadados nesse segmento.')
						} else {
							this.toastService.showDanger('Não é possível criar um checklist com os mesmos metadados de outro já existente.');
						}
					}

				}).add(() => this.isLoading = false);

		}
	}

	private validateFirstSteps(): void {
		this.createChecklistService.openNewChecklistModal().subscribe(
			result => {
				if (result === 'IMPORT') {
					this.activeModal.close();
				}
			});
	}

	castToChecklistItem(category): ChecklistItem {
		let savedItem = this.createChecklistService.currentChecklist.itensChecklist.find( item => category.id != undefined && item.id == category.id);

		let isActive = !!savedItem;

		if(!savedItem) {
			savedItem = this.createChecklistService.savedCategories.find(item => item.id == category.id);
		}

		if(category.id == undefined) {
			isActive = true;
		}

		const item = new ChecklistItem().deserialize({
			id: category.id,
			descricao: category.description ? category.description : category.descricao ? category.descricao : savedItem?.descricao,
			name: category.name,
			peso: !isActive ? 0 : savedItem ? savedItem.peso : category.peso,
			checklist: {
				id: this.createChecklistService.currentChecklist.id,
				type: this.createChecklistService.currentChecklist.checkListType
			},
			speechCategoryId: category._id ? category._id : category.speechCategoryId,
			defaultAnswer: savedItem ? savedItem.itemAnswer : category.itemAnswer,
			itemAnswer: savedItem ? savedItem.itemAnswer : category.itemAnswer,
			group: { name: (typeof category.group != 'string') ? category.group.name : category.group },
			active: isActive,
			ruleFragmentCategoryIA: category.ruleFragmentCategoryIA
		});

		if(!item.peso) {
			item.peso = 0;
		}

		return item;
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	public roundNumber(num, scale) {
		if(!("" + num).includes("e")) {
			return +(Math.round(Number(num + "e+" + scale))  + "e-" + scale);
		} else {
			var arr = ("" + num).split("e");
			var sig = ""
			if(+arr[1] + scale > 0) {
				sig = "+";
			}
			return +(Math.round(Number(+arr[0] + "e" + sig + (+arr[1] + scale))) + "e-" + scale);
		}
	}

}

function validatePesoTotal(form: FormArray): ValidationErrors | null {
	const totalPeso = form.value.reduce((acc, group) => (acc = [...acc, ...group]), []).reduce((acc, categoryPeso) => {
		return acc = acc + (categoryPeso.peso && categoryPeso.peso != 100 ? parseFloat(categoryPeso.peso) : 0);
	}, 0);
	if (totalPeso === 100) {
		return null;
	} else if (totalPeso > 0 && totalPeso < 100) {
		return {pesoNotEnough: true};
	} else if (totalPeso <= 0) {
		return {pesoLessOrEqualThanZero: true};
	} else {
		return {pesoGreaterThanMax: true};
	}
}
