import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Organization } from '@app/models';
import { Product } from '@app/models';
import { SwitchProductsOrganizations } from '../evo-switch/evo-switch.component';
import { OrganizationService } from '@app/services';
import { ProductService } from '@app/services';
import { switchMap } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
import { QualityPermissions } from '@app/models';
import { fadeInTop } from '../../_helpers/animations/fade.animations';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelpDialogComponent } from '../help-dialog/help-dialog.component';
import { environment } from '../../../environments/environment';
import { User } from '@app/models';
import { UserService } from '@app/services';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'fixed-top-header',
    templateUrl: './fixed-top-header.component.html',
    styleUrls: ['./fixed-top-header.component.scss'],
	animations: [ fadeInTop ]
})
export class FixedTopHeaderComponent implements OnInit {
    @Input() currentUser: User;
    modalRef: BsModalRef;

    changePassword = false;

	public products: Product[] = [];
	public organizations: Organization[] = [];

	public isInternal: boolean = false;

	public switchProductsOrganizations: SwitchProductsOrganizations;

	public showSwitch = false;
	public showSpeed = false;

	public homepage: string = environment.features.homepage;

	public showAdminBtn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	public showSupport: boolean = environment.features.enableSupport;
	public showFaq: boolean = environment.features.enableFaq;

	public get idUrl(): string {
		return environment.apps['id'];
	}

    constructor(public userService: UserService,
				public organizationService: OrganizationService,
				public productService: ProductService,
				public ngxPermissionsService: NgxPermissionsService,
				public modalService: NgbModal,
                private router: Router) {
    }

	ngOnInit() {
		this.organizationService.findAll().pipe(
			switchMap((value) => {
				this.organizations = value.filter(item => item.status == 'ACTIVE');
				return this.productService.findAll();
			})
		).subscribe(
			data => {
				this.switchProductsOrganizations = new SwitchProductsOrganizations();
				this.switchProductsOrganizations.allProducts = data as any;
				this.switchProductsOrganizations.allOrganizations = this.organizations;
			});

		this.userHasInternal();
		this.checkAdminPermission();
	}

	public checkAdminPermission(): void {
		let hasAdminPermission = false;

		this.userService.currentUser.organizations.some(org => {
			this.showAdminBtn.next(org.admin);

			return org.admin;
		});

		if(this.showAdminBtn.value || !this.userService.currentUser.roles || this.userService.currentUser.roles.length == 0) {
			return;
		}

		this.userService.currentUser.roles.some(role => {
			let adminPermission = role.permissions.filter(permission => permission.product == 'ADMIN');

			if(adminPermission.length > 0) {
				hasAdminPermission = true;
				this.showAdminBtn.next(true);
			}

			return hasAdminPermission;
		});
	}

	public get qualityPermissions(): typeof QualityPermissions {
		return QualityPermissions;
	}

    logout() {
        this.userService.logout();
    }

    myProfile() {
        this.router.navigate(['/myProfile']);
    }

	public goToOrganizationList(): void {
		// this.organizationService.forceOrganizationSelection = true;
		localStorage.removeItem('organizationId');
		this.router.navigate(['/organizations']);
	}

    /*organizations() {
        this.router.navigate(['/organizations']);
    }*/

	public openHelpDialog(): void {
		const modalRef = this.modalService.open(HelpDialogComponent, {});

		modalRef.result.then( data => {
			if(data) {
			}
		});
	}

	public userHasInternal(): void {
		this.ngxPermissionsService.hasPermission(QualityPermissions.INTERNAL).then(
			data => {
				this.isInternal = data;
			}
		)
	}
}
